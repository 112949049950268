import React, { useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";

const MarketAnalysisRow = ({ analysis, guidance, relatedEquities }) => {
  const [expanded, setExpanded] = useState(false);

  const processText = (text) => {
    return text ? text.replace(/\\n/g, '\n') : '';
  };

  const iconStyle = {
    width: '16px',
    height: '16px',
    color: '#6B7280', // Tailwind's gray-500
  };

  return (
    <div className="market-analysis p-4 bg-yellow-100 border-l-4 border-yellow-500 relative mb-4 rounded">
      <div className="flex justify-between items-center mb-2">
        <strong className="text-xl">Market Analysis</strong>
        <div className="ai-badge flex items-center bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
          <FontAwesomeIcon 
            icon={faMagicWandSparkles} 
            style={{...iconStyle, marginRight: '4px'}}
          />
          AI assisted
        </div>
      </div>
      <div className="my-2">
        {analysis && <ReactMarkdown>{processText(analysis)}</ReactMarkdown>}
      </div>
      <div className="related-equities">
        <button 
          onClick={() => setExpanded(!expanded)} 
          className="text-blue-500 underline flex items-center"
        >
          Guidance and Potential Plays 
          {expanded ? <FiChevronUp style={iconStyle} /> : <FiChevronDown style={iconStyle} />}
        </button>
        {expanded && (
          <div className="bg-white p-4 mt-2 rounded-md shadow-md">
            {guidance && (
              <div className="mb-4">
                <ReactMarkdown>{processText(guidance)}</ReactMarkdown>
              </div>
            )}
            <h3 className="text-lg font-semibold mb-2">Potential Plays</h3>
            {relatedEquities && relatedEquities.length > 0 && (
              <table className="min-w-full bg-white border">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b">Company</th>
                    <th className="py-2 px-4 border-b">Symbol</th>
                    <th className="py-2 px-4 border-b">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {relatedEquities.map((equity, idx) => (
                    <tr key={idx}>
                      <td className="py-2 px-4 border-b">{equity.Company}</td>
                      <td className="py-2 px-4 border-b">{equity.Symbol}</td>
                      <td className="py-2 px-4 border-b">{equity.Reason}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketAnalysisRow;
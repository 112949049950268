import React, { useState, useEffect } from 'react';
import './EarningsWidget.css'; // Create appropriate CSS for styling

const EarningsWidget = ({ apiUrl }) => {
  const [data, setData] = useState(null);
  const [timeframe, setTimeframe] = useState('15d'); // Default timeframe
  const [isFolded, setIsFolded] = useState(true); // State to control folded state

  useEffect(() => {
    const calculateFromDate = (timeframe) => {
      const now = new Date();
      let fromDate;

      switch (timeframe) {
        case '15d':
          fromDate = new Date(now.setDate(now.getDate() - 15));
          break;
        case '1mo':
          fromDate = new Date(now.setMonth(now.getMonth() - 1));
          break;
        case '3mo':
          fromDate = new Date(now.setMonth(now.getMonth() - 3));
          break;
        default:
          fromDate = new Date(now.setDate(now.getDate() - 15));
      }

      return fromDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };

    const fromDate = calculateFromDate(timeframe);

    fetch(`${apiUrl}?from=${fromDate}`) // Adjust the 'from' date based on timeframe
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [apiUrl, timeframe]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const total = data.numPriceIncreases + data.numPriceDecreases;
  const increaseWidth = (data.numPriceIncreases / total) * 100;
  const decreaseWidth = (data.numPriceDecreases / total) * 100;

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  const toggleFold = () => {
    setIsFolded(!isFolded);
  };

  return (
    <div className="earnings-widget" onClick={toggleFold}>
      <div className="widget-header">
        <h2>Recent Earnings Reaction</h2>
        <select value={timeframe} onClick={(e) => e.stopPropagation()} onChange={handleTimeframeChange} className="timeframe-select">
          <option value="15d">15 days</option>
          <option value="1mo">1 month</option>
          <option value="3mo">3 months</option>
        </select>
      </div>
      <div className="earnings-bar">
        <div className="bar-increase" style={{ width: `${increaseWidth}%` }}>
          {data.numPriceIncreases}
        </div>
        <div className="bar-decrease" style={{ width: `${decreaseWidth}%` }}>
          {data.numPriceDecreases}
        </div>
      </div>
      {!isFolded && (
        <div className="earnings-details">
          <div className="increases">
            <h3>Up After Earnings</h3>
            <ul>
              {data.topIndustriesIncreases.map((industry, index) => (
                <li key={index}>{industry.industry}: {industry.count}</li>
              ))}
            </ul>
          </div>
          <div className="decreases">
            <h3>Down After Earnings</h3>
            <ul>
              {data.topIndustriesDecreases.map((industry, index) => (
                <li key={index}>{industry.industry}: {industry.count}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default EarningsWidget;

// utils.js
import React from "react";

const formatDate = (isoDateString) => {
  try {
    if (!isoDateString) {
      console.error("Empty date string provided");
      return "N/A";
    }
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", isoDateString);
      return "N/A";
    }
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "N/A";
  }
};

const formatTrafficData = (
  currentVisits,
  preEarningsVisits,
  trafficDate,
  latestEarningsDate,
  trafficChange,
) => {
  console.log("formatTrafficData input:", {
    currentVisits,
    preEarningsVisits,
    trafficDate,
    latestEarningsDate,
    trafficChange,
  });

  if (currentVisits == null || preEarningsVisits == null) {
    console.warn("Invalid visit data:", { currentVisits, preEarningsVisits });
    return {
      current: "N/A",
      change: "N/A",
      changeClass: "",
      date: formatDate(trafficDate) || "N/A",
      earningsDate: formatDate(latestEarningsDate) || "N/A",
    };
  }

  return {
    current: currentVisits.toLocaleString(),
    change: formatChange(trafficChange),
    changeClass: getChangeClass(trafficChange),
    date: formatDate(trafficDate) || "N/A",
    earningsDate: formatDate(latestEarningsDate) || "N/A",
  };
};

const daysUntilEarnings = (isoDateString) => {
  try {
    if (!isoDateString) {
      console.error("Empty date string provided");
      return null;
    }
    const earningsDate = new Date(isoDateString);
    if (isNaN(earningsDate.getTime())) {
      console.error("Invalid earnings date:", isoDateString);
      return null;
    }
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    earningsDate.setHours(0, 0, 0, 0);
    const timeDiff = earningsDate.getTime() - currentDate.getTime();
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  } catch (error) {
    console.error("Error calculating days until earnings:", error);
    return null;
  }
};

const renderEarningsDays = (date) => {
  const days = daysUntilEarnings(date);
  let badgeClass = "";
  let badgeText = "";
  if (days === null) {
    badgeClass = "bg-gray-200";
    badgeText = "N/A";
  } else if (days < 0) {
    badgeClass = "bg-red-500 text-white";
    const daysAgo = Math.abs(days);
    badgeText = `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
  } else if (days === 0) {
    badgeClass = "bg-gray-800 text-white";
    badgeText = "Today";
  } else {
    badgeClass = "bg-blue-200";
    badgeText = `${days} day${days > 1 ? "s" : ""}`;
  }
  return (
    <span
      className={`inline-block rounded-full px-2 py-1 text-xs font-semibold ${badgeClass}`}
    >
      {badgeText}
    </span>
  );
};

const getChangeClass = (change) => {
  return parseFloat(change) >= 0 ? "text-green-500" : "text-red-500";
};

const formatChange = (change) => {
  if (change === null || isNaN(change)) return "N/A";
  const percentage = parseFloat(change);
  return `${percentage.toFixed(2)}%`;
};

const getRowClass = (company) => {
  const trafficChange = company.trafficChange;
  const stockChange = company.stockChange;
  const isToday = daysUntilEarnings(company.earningsDate) === 0;

  if (trafficChange === null || stockChange === null) return "";

  if (trafficChange > 0 && stockChange <= trafficChange) {
    return "bg-green-50";
  } else if (trafficChange < 0 && (stockChange >= trafficChange || stockChange >= 0)) {
    return "bg-red-50";
  }

  return isToday ? "border-4 border-yellow-500" : "";
};

const estimateNextEarningsDate = (lastEarningsDate) => {
  if (!lastEarningsDate) return null;
  const lastDate = new Date(lastEarningsDate);
  return new Date(lastDate.setMonth(lastDate.getMonth() + 3))
    .toISOString()
    .split("T")[0];
};

const renderEconomicEventDetails = (details) => {
  if (!details) {
    return <div>No details available.</div>;
  }

  return (
    <div className="text-sm text-gray-700 mt-1 p-2 border rounded-md bg-gray-50">
      {details.source && (
        <p className="mb-1">
          <strong>Source:</strong>{" "}
          <span dangerouslySetInnerHTML={{ __html: details.source }} />
        </p>
      )}
      {details.measures && (
        <p className="mb-1">
          <strong>Measures:</strong>{" "}
          <span dangerouslySetInnerHTML={{ __html: details.measures }} />
        </p>
      )}
      {details.usual_effect && (
        <p className="mb-1">
          <strong>Usual Effect:</strong>{" "}
          <span dangerouslySetInnerHTML={{ __html: details.usual_effect }} />
        </p>
      )}
      {details.frequency && (
        <p className="mb-1">
          <strong>Frequency:</strong> {details.frequency}
        </p>
      )}
      {details.why_care && (
        <p className="mb-1">
          <strong>Why Care:</strong>{" "}
          <span dangerouslySetInnerHTML={{ __html: details.why_care }} />
        </p>
      )}
      {details.also_called && (
        <p className="mb-1">
          <strong>Also called:</strong> {details.also_called}
        </p>
      )}
      {details.expanded && (
        <p className="mb-1">
          <strong>Expanded:</strong> {details.expanded}
        </p>
      )}
      {details.notes && (
        <p className="mb-1">
          <strong>Notes:</strong> {details.notes}
        </p>
      )}
      {details.helpful_links && details.helpful_links.length > 0 && (
        <ul className="list-disc list-inside pl-4">
          {details.helpful_links.map((link, linkIdx) => (
            <li key={linkIdx}>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export {
  formatDate,
  daysUntilEarnings,
  renderEarningsDays,
  getChangeClass,
  formatChange,
  getRowClass,
  estimateNextEarningsDate,
  renderEconomicEventDetails,
  formatTrafficData,
};
export const getRowStyle = (company, colorSettings) => {
  if (!colorSettings || colorSettings.length === 0) {
    return {}; // Return empty object if no color settings
  }

  for (const rule of colorSettings) {
    const conditionsMet = rule.conditions.every(condition => {
      const value = parseFloat(company[condition.field]);
      const conditionValue = parseFloat(condition.value);
      
      switch (condition.operator) {
        case '>': return value > conditionValue;
        case '<': return value < conditionValue;
        case '=': return value === conditionValue;
        case '>=': return value >= conditionValue;
        case '<=': return value <= conditionValue;
        default: return false;
      }
    });

    if (conditionsMet) {
      //console.log(`Conditions met for rule: ${JSON.stringify(rule)}, applying color: ${rule.color}`);
      return { backgroundColor: rule.color };
    }
  }
  return {};
};


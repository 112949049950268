import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useWatchlistData, fetchScreenData, useEconomicData, useMarketAnalysis, updateWatchlist } from '../services/api';
import StockTable from './StockTable';
import CONFIG from '../config/config';
import axios from 'axios';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import { jwtDecode } from 'jwt-decode';
import EarningsWidget from './EarningsWidget';
import EconomicEventControls from './EconomicEventControls';
import './EconomicEventControls.css';
import AddSymbolButton from './AddSymbolButton';
import Modal from 'react-modal';
import ProfileModal from './ProfileModal';
import { DEFAULT_COLOR_SETTINGS } from '../config/constants';
import MarketAnalysisRow from './MarketAnalysisRow';

library.add(fas);

// Define the semi-permanent status message
const APP_STATUS = {
  message: "This app is still in active development mode. We're launching very soon!",
  type: "info"
};

const getIconName = (iconString) => {
  return iconString.startsWith('fa-') ? iconString.slice(3) : iconString;
};

const calculateTrialDaysLeft = (timestamp) => {
  const today = new Date();
  const firstUseDate = new Date(timestamp);
  const diffTime = Math.abs(today - firstUseDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.max(0, 21 - diffDays);
};

const App = () => {
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesSymbols, setCompaniesSymbols] = useState(() => {
    const savedSymbols = localStorage.getItem('companiesSymbols');
    return savedSymbols ? JSON.parse(savedSymbols) : [];
  });
  const [selectedTab, setSelectedTab] = useState('watchlist');
  const [categories, setCategories] = useState([]);
  const [news, setNews] = useState([]);
  const [analysis, setAnalysis] = useState('');
  const [guidance, setGuidance] = useState('');
  const [relatedEquities, setRelatedEquities] = useState([]);
  const [error, setError] = useState('');
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [firstUseTimestamp, setFirstUseTimestamp] = useState(() => {
    const savedTimestamp = localStorage.getItem('firstUseTimestamp');
    return savedTimestamp || new Date().toISOString();
  });
  const [trialDaysLeft, setTrialDaysLeft] = useState(() => calculateTrialDaysLeft(firstUseTimestamp));
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [selectedTimeframes, setSelectedTimeframes] = useState([0]);
  const [geography, setGeography] = useState('US');
  const [showEconomicEvents, setShowEconomicEvents] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('info');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: null });
  const [colorSettings, setColorSettings] = useState(() => {
    const savedSettings = localStorage.getItem('colorSettings');
    return savedSettings ? JSON.parse(savedSettings) : DEFAULT_COLOR_SETTINGS;
  });

  const isSyncing = useRef(false);
  const lastSyncedData = useRef({});

  const { data: marketAnalysisData, isLoading: isLoadingMarketAnalysis } = useMarketAnalysis();
  const { data: watchlistData, refetch: refetchWatchlistData } = useWatchlistData(loggedInUser);

  const updateUserWatchlist = useCallback(async (symbols) => {
    if (!loggedInUser) return;
    try {
      await updateWatchlist(loggedInUser, symbols);
      await refetchWatchlistData();
    } catch (error) {
      console.error('Error updating user watchlist:', error);
      setError('Failed to update watchlist. Please try again.');
    }
  }, [loggedInUser, refetchWatchlistData]);

  const syncUserData = useCallback(async (email) => {
    if (!email || isSyncing.current) return;
    isSyncing.current = true;
    console.log('Syncing user data for', email);
  
    try {
      const result = await refetchWatchlistData();
      console.log('Fetched watchlist data:', result);
  
      let serverSymbols = [];
      if (result && result.data && Array.isArray(result.data)) {
        serverSymbols = result.data.map(stock => stock.symbol);
      } else if (result && Array.isArray(result)) {
        serverSymbols = result.map(stock => stock.symbol);
      } else {
        console.warn('Unexpected watchlist data format:', result);
      }
  
      const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
      const mergedSymbols = Array.from(new Set([...localSymbols, ...serverSymbols]));
      
      setCompaniesSymbols(mergedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(mergedSymbols));
  
      if (mergedSymbols.length > serverSymbols.length) {
        await updateUserWatchlist(mergedSymbols);
      }
  
      console.log('User data synced successfully');
    } catch (error) {
      console.error('Error syncing user data:', error);
      const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
      setCompaniesSymbols(localSymbols);
    } finally {
      isSyncing.current = false;
    }
  }, [refetchWatchlistData, updateUserWatchlist]);


useEffect(() => {
    if (marketAnalysisData) {
      console.log('Market Analysis Data:', marketAnalysisData); 
      setNews(marketAnalysisData.articles);
      setAnalysis(marketAnalysisData.analysis);
      setGuidance(marketAnalysisData.guidance);
      setRelatedEquities(marketAnalysisData.relatedEquities);
    }
  }, [marketAnalysisData]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
      },
    },
  });

  queryClient.setQueryDefaults(['marketAnalysis'], {
    staleTime: 60 * 60 * 1000, // 1 hour
    cacheTime: 2 * 60 * 60 * 1000, // 2 hours
  });

  

  const { 
    data: allEconomicEvents, 
    isLoading: isLoadingEconomicEvents, 
    error: economicError 
  } = useEconomicData(geography);



  const fetchNews = async () => {
    console.log('Fetching news');
    const today = new Date().toISOString().split('T')[0];
    try {
      const response = await axios.get(`https://market_news.drisw.workers.dev/?date=${today}&category=239`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch news:', error);
      throw error;
    }
  };

  const fetchAllData = useCallback(async (isInitial = false) => {
    if (!isInitial) setIsLoading(true);
    // Do not reset error to null here; accumulate errors if any
    let screenData = [];
    let newsData = {};
  
    // Try to fetch screenData
    try {
      if (selectedTab === 'watchlist') {
        try {
          if (loggedInUser) {
            const result = await refetchWatchlistData();
            console.log('Raw watchlist data:', result);
            screenData = result.data?.stocks || result.data || result || [];
          } else {
            const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
            screenData = await fetchScreenData(null, null, localSymbols);
          }
        } catch (err) {
          console.error('Error fetching watchlist:', err);
          // Removed the setError call to prevent displaying the message to the user
          // Optionally, you can log a different message or handle it silently
          // setError(prevError => (prevError ? prevError + '\n' : '') + 'Failed to fetch watchlist. Using last known data.');
          
          // Use last known data if available
          screenData = companiesData || [];
        }
      } else {
        screenData = await fetchScreenData(selectedTab);
      }
  
      if (screenData && typeof screenData === 'object' && !Array.isArray(screenData)) {
        screenData = screenData.stocks || Object.values(screenData);
      }
  
      if (!Array.isArray(screenData)) {
        console.error('Unexpected screenData format:', screenData);
        // Instead of throwing an error, set screenData to empty array
        screenData = [];
      }
    } catch (err) {
      console.error('Error fetching screen data:', err);
      setError('Some data could not be fetched. Please try again later.');
      screenData = [];
    }
  
    // Try to fetch newsData
    try {
      newsData = await fetchNews();
    } catch (err) {
      console.error('Failed to fetch news:', err);
      setError(prevError => (prevError ? prevError + '\n' : '') + 'Failed to fetch news data.');
      newsData = {};
    }
  
    setCompaniesData(screenData);
    setNews(newsData.articles || []);
    setAnalysis(newsData.analysis || '');
    setGuidance(newsData.guidance || '');
    setRelatedEquities(newsData.relatedEquities || []);
  
    setIsLoading(false);
    if (isInitial) setIsInitialLoad(false);
  }, [selectedTab, loggedInUser, refetchWatchlistData]);

  useEffect(() => {
    fetchAllData(true);
  }, []);

  useEffect(() => {
    if (!isInitialLoad) {
      fetchAllData();
    }
  }, [selectedTab, fetchAllData, isInitialLoad]);



  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://screen_query.drisw.workers.dev/available-screens');
        setCategories([
          { id: 'watchlist', name: 'Watch List', icon: 'fa-star', description: 'Your personal watchlist' },
          ...response.data
        ]);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        setCategories([{ id: 'watchlist', name: 'Watch List', icon: 'fa-star', description: 'Your personal watchlist' }]);
      }
    };
    fetchCategories();
  }, []);

  const debouncedSync = useCallback(
    debounce((email, data) => {
      if (JSON.stringify(data) !== JSON.stringify(lastSyncedData.current)) {
        syncUserData(email, data);
        lastSyncedData.current = data;
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (loggedInUser && companiesSymbols.length > 0) {
      debouncedSync(loggedInUser, companiesSymbols);
    }
  }, [loggedInUser, companiesSymbols, debouncedSync]);

  const handleAddToWatchList = useCallback(async (company) => {
    const tickerSymbol = company.tickerSymbol;
    if (companiesSymbols.includes(tickerSymbol)) {
      setError(`Company already exists in the watch list: ${tickerSymbol}`);
      setTimeout(() => setError(''), 5000);
      return;
    }
  
    const updatedSymbols = [...companiesSymbols, tickerSymbol];
    try {
      setCompaniesSymbols(updatedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(updatedSymbols));
  
      if (loggedInUser) {
        await updateUserWatchlist(updatedSymbols);
      }
  
      await fetchAllData();
    } catch (error) {
      console.error(`Error adding ${tickerSymbol} to watch list:`, error);
      setError(`Failed to add ${tickerSymbol} to watch list.`);
      setTimeout(() => setError(''), 5000);
      setCompaniesSymbols(companiesSymbols);
    }
  }, [companiesSymbols, loggedInUser, updateUserWatchlist, fetchAllData]);

  const handleDeleteFromWatchList = useCallback(async (tickerSymbol) => {
    try {
      const updatedSymbols = companiesSymbols.filter(symbol => symbol !== tickerSymbol);
      setCompaniesSymbols(updatedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(updatedSymbols));
  
      setCompaniesData(prevData => prevData.filter(company => company.tickerSymbol !== tickerSymbol));
  
      if (loggedInUser) {
        await updateUserWatchlist(updatedSymbols);
      }
    } catch (error) {
      console.error(`Error deleting ${tickerSymbol} from watchlist:`, error);
      setError(`Failed to delete ${tickerSymbol} from watchlist.`);
      setTimeout(() => setError(''), 5000);
    }
  }, [companiesSymbols, loggedInUser, updateUserWatchlist]);

  const handleAddSymbol = useCallback(async (tickerSymbol) => {
    if (companiesSymbols.includes(tickerSymbol)) {
      setError(`${tickerSymbol} is already in your watch list.`);
      setStatusType('error');
      setTimeout(() => setError(''), 5000);
      return;
    }

    setIsLoading(true);
    try {
      const updatedSymbols = [...companiesSymbols, tickerSymbol];
      console.log('Updating watchlist with:', updatedSymbols);
      
      if (loggedInUser) {
        console.log('Updating user watchlist on server');
        await updateWatchlist(loggedInUser, updatedSymbols);
      }

      setCompaniesSymbols(updatedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(updatedSymbols));

      // Fetch the data for the new symbol to add to companiesData
      const newSymbolData = await fetchScreenData(null, null, [tickerSymbol]);
      if (newSymbolData && newSymbolData.length > 0) {
        setCompaniesData(prevData => [...prevData, newSymbolData[0]]);
      }

      setStatusMessage(`${tickerSymbol} added to watch list successfully.`);
      setStatusType('success');
      
      // Refresh the watchlist data
      await queryClient.invalidateQueries(['companies', 'watchlist']);
    } catch (error) {
      console.error('Error adding new symbol:', error);
      setError(`Failed to add ${tickerSymbol} to watch list: ${error.message}`);
      setStatusType('error');
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setError('');
        setStatusMessage('');
      }, 5000);
    }
  }, [companiesSymbols, loggedInUser, updateWatchlist, queryClient, setError, setStatusMessage, setStatusType]);

  const handleLoginSuccess = useCallback((email) => {
    setLoggedInUser(email);
    syncUserData(email);
    setStatusMessage(`Welcome back, ${email}!`);
    setStatusType('success');
    setTimeout(() => setStatusMessage(''), 5000); // Clear message after 5 seconds
  }, [syncUserData]);
  
  useEffect(() => {
    const initializeUserData = async () => {
      if (loggedInUser && !isSyncing.current) {
        setIsLoading(true);
        isSyncing.current = true;
        try {
          await syncUserData(loggedInUser);
          await queryClient.invalidateQueries(['userData', loggedInUser]);
          await queryClient.invalidateQueries(['economicData']);
          await queryClient.invalidateQueries(['companies']);
          await fetchAllData();
        } catch (error) {
          console.error('Error initializing user data:', error);
          setError('Failed to initialize user data. Please try again.');
        } finally {
          setIsLoading(false);
          isSyncing.current = false;
        }
      }
    };
  
    initializeUserData();
  }, [loggedInUser]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken.email) {
        setLoggedInUser(decodedToken.email);
      } else {
        setLoggedInUser(null);
      }
    } else {
      setLoggedInUser(null);
    }
  }, []);

  const filteredEconomicEvents = useMemo(() => {
    if (!allEconomicEvents || !showEconomicEvents) return [];
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
  
    return Object.entries(allEconomicEvents).flatMap(([date, events]) => {
      const eventDate = new Date(date);
      const weekDiff = Math.floor((eventDate - startOfWeek) / (7 * 24 * 60 * 60 * 1000));
      
      if (selectedTimeframes.includes(weekDiff)) {
        return events.map(event => ({
          ...event,
          date,
          timeframe: weekDiff
        }));
      }
      return [];
    });
  }, [allEconomicEvents, selectedTimeframes, showEconomicEvents]);

  const memoizedStockTable = useMemo(() => (
    <StockTable
      companyData={companiesData}
      economicEvents={filteredEconomicEvents}
      onAddToWatchList={handleAddToWatchList}
      onDelete={handleDeleteFromWatchList}
      news={news}
      analysis={analysis}
      guidance={guidance}
      relatedEquities={relatedEquities}
      loadingTable={isLoading}
      selectedTimeframes={selectedTimeframes}
      setSelectedTimeframes={setSelectedTimeframes}
      geography={geography}
      setGeography={setGeography}
      showEconomicEvents={showEconomicEvents}
      setShowEconomicEvents={setShowEconomicEvents}
      selectedTab={selectedTab}
      colorSettings={colorSettings}
    />
  ), [companiesData, filteredEconomicEvents, handleAddToWatchList, handleDeleteFromWatchList, news, analysis, guidance, relatedEquities, isLoading, selectedTimeframes, setSelectedTimeframes, geography, setGeography, showEconomicEvents, setShowEconomicEvents, selectedTab, colorSettings]);

  console.log('APP_STATUS in App.js:', APP_STATUS);

  const handleDeleteProfile = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete your profile? This action cannot be undone.")) {
      try {
        // Implement the delete profile logic here
        // For example:
        // await deleteUserProfile(loggedInUser);
        setLoggedInUser(null);
        localStorage.removeItem('authToken');
        setModalIsOpen(false);
        setStatusMessage('Your profile has been deleted successfully.');
        setStatusType('success');
      } catch (error) {
        console.error('Error deleting profile:', error);
        setStatusMessage('Failed to delete profile. Please try again.');
        setStatusType('error');
      }
    }
  }, [loggedInUser, setStatusMessage, setStatusType]);

  const handleProfileClick = useCallback(() => {
    setModalContent({
      title: 'Profile',
      content: <ProfileModal loggedInUser={loggedInUser} onDeleteProfile={handleDeleteProfile} onClose={() => setModalIsOpen(false)} />
    });
    setModalIsOpen(true);
  }, [loggedInUser, handleDeleteProfile]);

  const handleSubscriptionClick = useCallback(() => {
    setModalContent({
      title: 'Subscription',
      content: <p>Subscription management options will go here.</p>
    });
    setModalIsOpen(true);
  }, []);

  useEffect(() => {
    console.log('Modal open state changed:', modalIsOpen);
  }, [modalIsOpen]);

  const handleSettingsSave = useCallback((newSettings) => {
    console.log('New settings:', newSettings);
    setColorSettings(newSettings);
    localStorage.setItem('colorSettings', JSON.stringify(newSettings));
  }, []);

  const handleAddSymbolClick = () => {
    // This should trigger the same action as the floating AddSymbolButton
    const floatingButton = document.querySelector('.add-symbol-button');
    if (floatingButton) {
      floatingButton.click();
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
    <div className="container mx-auto mt-4 px-4">
      <Header 
        onLoginSuccess={handleLoginSuccess}
        onLogout={() => {
          setLoggedInUser(null);
          setStatusMessage('You have been logged out.');
          setStatusType('info');
          setTimeout(() => setStatusMessage(''), 5000);
        }}
        trialDaysLeft={trialDaysLeft} 
        isSubscribed={isSubscribed} 
        statusMessage={statusMessage}
        statusType={statusType}
        appStatus={APP_STATUS}  // Always pass the APP_STATUS
        onProfileClick={handleProfileClick}
        onSubscriptionClick={handleSubscriptionClick}
        onSettingsSave={handleSettingsSave}
      />
      <EarningsWidget apiUrl={CONFIG.API_URLS.EARNINGS_ANALYTICS} />
      <MarketAnalysisRow
        analysis={analysis}
        guidance={guidance}
        relatedEquities={relatedEquities}
      />
      {error && <div className="text-red-500 text-center p-4">{error}</div>}
      <div className="flex flex-wrap items-center mb-4 gap-2">
        {categories.map((category, index) => (
          <React.Fragment key={category.id}>
            <div className="flex overflow-hidden rounded">
              <button
                className={`flex items-center px-4 py-2 ${
                  selectedTab === category.id
                    ? 'bg-blue-500 text-white'
                    : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-200'
                } ${category.id === 'watchlist' ? 'rounded-l' : 'rounded'}`}
                onClick={() => setSelectedTab(category.id)}
                title={category.description}
              >
                {category.icon && (
                  <FontAwesomeIcon
                    icon={getIconName(category.icon)}
                    className="mr-2"
                    onError={(e) => {
                      console.warn(`Failed to load icon: ${category.icon}`);
                      e.target.style.display = 'none';
                    }}
                  />
                )}
                {category.name}
              </button>
              {category.id === 'watchlist' && (
                <AddSymbolButton
                  onAddSymbol={handleAddSymbol}
                  existingSymbols={companiesSymbols}
                  isLoggedIn={!!loggedInUser}
                  className="rounded-r bg-blue-500 text-white px-2 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                  title="Add symbol to watchlist"
                >
                  +
                </AddSymbolButton>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
      {memoizedStockTable}
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={modalContent.title}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-auto">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">{modalContent.title}</h2>
            <button
              onClick={() => setModalIsOpen(false)}
              className="text-gray-400 hover:text-gray-600 text-xl font-bold"
              aria-label="Close"
            >
              ×
            </button>
          </div>
          <div className="p-4">
            {modalContent.content}
          </div>
        </div>
      </Modal>
    </div>
    </QueryClientProvider>
  );
};

export default React.memo(App);
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons"; 
import TradingRangeInsights from './TradingRangeInsights'; // Import the new component

const AiAnalysisPanel = ({ aiAnalysis, stock }) => {
  if (!aiAnalysis || !stock) {
    return <div>No AI Analysis or stock data available.</div>;
  }

  const {
    recommendation_rating,
    recommendation_explanation,
    summary_rating,
    analysis_data,
    updated_at,
  } = aiAnalysis;

  if (!analysis_data) {
    return <div>No detailed analysis data available.</div>;
  }

  return (
    <>
      {/* Trading Range Insights */}
      <TradingRangeInsights stock={stock} />

      {/* Company Description */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-xl font-semibold mb-2">Company Description</h3>
        <p>{analysis_data.description}</p>
      </div>

      {/* Market Size and Growth */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-xl font-semibold mb-2">Market Size and Growth</h3>
        <p>{analysis_data.market_size_and_growth}</p>
      </div>

      {/* Financial Analysis */}
      {analysis_data.financial_analysis && (
        <div className="bg-white rounded-lg shadow p-6 mb-4">
          <h3 className="text-xl font-semibold mb-4">Financial Analysis</h3>
          <table className="w-full text-left">
            <tbody>
              {Object.entries(analysis_data.financial_analysis).map(([key, value]) => (
                <tr key={key} className="border-b border-gray-200">
                  <th className="py-2 pr-4 font-medium">{key.replace(/_/g, " ")}</th>
                  <td className="py-2">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Competition */}
      {analysis_data.competition &&
        analysis_data.competition.competitors && (
          <div className="bg-white rounded-lg shadow p-6 mb-4">
            <h3 className="text-xl font-semibold mb-4">Competition</h3>
            <table className="min-w-full text-left text-sm">
              <thead>
                <tr className="border-b">
                  <th className="py-2 px-4 font-medium">Competitor</th>
                  <th className="py-2 px-4 font-medium">Differentiation</th>
                </tr>
              </thead>
              <tbody>
                {analysis_data.competition.competitors.map(
                  (competitor, index) => (
                    <tr key={index} className="border-b">
                      <td className="py-2 px-4 font-medium">
                        {competitor.name}
                      </td>
                      <td className="py-2 px-4">
                        {competitor.differentiation}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}

      {/* Growth Prospects */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-xl font-semibold mb-2">Growth Prospects</h3>
        <p>{analysis_data.growth_prospects}</p>
      </div>

      {/* SWOT Analysis */}
      {analysis_data.swot_analysis && (
        <div className="bg-white rounded-lg shadow p-6 mb-4">
          <h3 className="text-xl font-semibold mb-4">SWOT Analysis</h3>
          <table className="min-w-full text-left text-sm">
            <thead>
              <tr className="border-b">
                <th className="py-2 px-4 font-medium">Strengths</th>
                <th className="py-2 px-4 font-medium">Weaknesses</th>
                <th className="py-2 px-4 font-medium">Opportunities</th>
                <th className="py-2 px-4 font-medium">Threats</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 align-top">
                  <ul className="list-disc list-inside text-gray-700">
                    {analysis_data.swot_analysis.strengths?.map(
                      (item, index) => (
                        <li key={index}>{item}</li>
                      )
                    )}
                  </ul>
                </td>
                <td className="py-2 px-4 align-top">
                  <ul className="list-disc list-inside text-gray-700">
                    {analysis_data.swot_analysis.weaknesses?.map(
                      (item, index) => (
                        <li key={index}>{item}</li>
                      )
                    )}
                  </ul>
                </td>
                <td className="py-2 px-4 align-top">
                  <ul className="list-disc list-inside text-gray-700">
                    {analysis_data.swot_analysis.opportunities?.map(
                      (item, index) => (
                        <li key={index}>{item}</li>
                      )
                    )}
                  </ul>
                </td>
                <td className="py-2 px-4 align-top">
                  <ul className="list-disc list-inside text-gray-700">
                    {analysis_data.swot_analysis.threats?.map(
                      (item, index) => (
                        <li key={index}>{item}</li>
                      )
                    )}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* Key Developments and Risk Factors */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        {/* Key Developments */}
        {analysis_data.key_developments && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Key Developments</h3>
            <ul className="list-disc list-inside text-gray-700">
              {analysis_data.key_developments.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Risk Factors */}
        {analysis_data.risk_factors && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Risk Factors</h3>
            <ul className="list-disc list-inside text-gray-700">
              {analysis_data.risk_factors.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Updated At */}
      <div className="text-sm text-gray-500 mt-4">
        <p>Generated with help of {aiAnalysis.llm_model || 'AI'} model. As usual with LLMs, subject to possible "hallucinations". Please do your own research. Last updated: {updated_at}</p>
      </div>
    </>
  );
};

export default AiAnalysisPanel;
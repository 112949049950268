import React, { useRef, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEllipsisV,
  faMagicWandSparkles
} from "@fortawesome/free-solid-svg-icons";
// Add these imports
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Favicon from "./Favicon";
import AiAnalysisPanel from "./AiAnalysisPanel"; 
import "./CompanyRow.css";

import {
  formatDate,
  daysUntilEarnings,
  getChangeClass,
  formatChange,
  getRowClass,
  getRowStyle,
} from "./utils";


const formatEmployeeOutlook = (outlook) => {
  const numericOutlook = typeof outlook === "string" ? parseFloat(outlook) : outlook;
  if (isNaN(numericOutlook) || numericOutlook < 0) {
    return "N/A";
  }
  return numericOutlook.toFixed(2);
};

const TrafficCell = ({ trafficData, trafficChange }) => {
  if (!trafficData || trafficData.length === 0) {
    return <div>N/A</div>;
  }

  const currentTraffic = trafficData[0].visits;

  return (
    <div>
      {currentTraffic.toLocaleString()}{" "}
      <span className={getChangeClass(trafficChange)}>
        {formatChange(trafficChange)}
      </span>
    </div>
  );
};

const EarningsBadge = ({ date, isEstimated }) => {
  const [tooltipStyle, setTooltipStyle] = useState({});
  const iconRef = useRef(null);

  const updateTooltipPosition = () => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      setTooltipStyle({
        left: `${rect.left}px`,
        top: `${rect.top - 30}px`, // Position above the icon
      });
    }
  };

  const days = daysUntilEarnings(date);
  let badgeClass = "";
  let badgeContent = "";

  if (days === null) {
    badgeClass = "bg-gray-200";
    badgeContent = "N/A";
  } else if (days < 0) {
    badgeClass = "bg-red-500 text-white";
    const daysAgo = Math.abs(days);
    badgeContent = `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
  } else if (days === 0) {
    badgeClass = "bg-gray-800 text-white";
    badgeContent = "Today";
  } else {
    badgeClass = "bg-blue-200";
    badgeContent = `${days} day${days > 1 ? "s" : ""}`;
  }

  return (
    <span
      className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-semibold ${badgeClass}`}
    >
      {isEstimated && (
        <span
          className="earnings-estimate-tooltip-container"
          onMouseEnter={updateTooltipPosition}
        >
          <FontAwesomeIcon
            ref={iconRef}
            icon={faExclamationTriangle}
            className="earnings-estimate-icon"
          />
          <span className="earnings-estimate-tooltip" style={tooltipStyle}>
            Estimated earnings date
          </span>
        </span>
      )}
      {badgeContent}
    </span>
  );
};

const CompanyRow = ({
  company,
  expandedRow,
  toggleRow,
  onAddToWatchList,
  onDelete,
  dateColumnStyle,
  selectedTab,
  colorSettings,
}) => {
  const rowStyle = getRowStyle(company, colorSettings);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = useCallback((e) => {
    e.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  }, []);

  const handleAddToWatchList = useCallback((e) => {
    e.stopPropagation();
    onAddToWatchList(company);
    setIsDropdownOpen(false);
  }, [company, onAddToWatchList]);

  const handleDelete = useCallback(async (e) => {
    e.stopPropagation();
    try {
      await onDelete(company.tickerSymbol);
    } catch (error) {
      console.error("Error deleting company:", error);
    }
    setIsDropdownOpen(false);
  }, [company.tickerSymbol, onDelete]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const renderLogo = () => {
    if (company.logo_url) {
      return (
        <img
          src={company.logo_url}
          alt={`${company.name} logo`}
          className="w-6 h-6 mr-2"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(company.name)}&background=random`;
          }}
        />
      );
    } else {
      return (
        <div className="w-6 h-6 mr-2 bg-gray-200 flex items-center justify-center rounded-full">
          {company.name.charAt(0)}
        </div>
      );
    }
  };

  const iconStyle = {
    width: '16px',
    height: '16px',
    color: '#6B7280', // Tailwind's gray-500
  };

  return (
    <>
      <tr
        className="hover:bg-gray-50 cursor-pointer"
        style={rowStyle}
        onClick={() => toggleRow(company.tickerSymbol)}
      >
        <td className="py-2 px-4 border-b" style={dateColumnStyle}>
          <div className="text-sm">
            {formatDate(company.earningsDate)}
            <br />
            <EarningsBadge
              date={company.earningsDate}
              isEstimated={company.isEarningsDateEstimated}
            />
          </div>
        </td>
        <td className="py-2 px-4 border-b">
          <div className="flex items-center">
            {renderLogo()}
            <div>
              <strong>
                {company.name}{" "}
                <span className="text-gray-500">({company.tickerSymbol})</span>
              </strong>
              <br />
              {company.website && (
                <a
                  href={
                    company.website.startsWith("http")
                      ? company.website
                      : `https://${company.website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {company.website}
                </a>
              )}
            </div>
            <div className="ml-auto relative" ref={dropdownRef}>
              <button
                className={`text-gray-600 hover:text-gray-800 focus:outline-none p-1 rounded-full transition-colors duration-200 ease-in-out ${
                  isDropdownOpen ? 'bg-gray-200' : 'hover:bg-gray-100'
                }`}
                type="button"
                onClick={handleDropdownToggle}
                aria-label="Company options"
              >
                <FontAwesomeIcon icon={faEllipsisV} className="w-4 h-4" />
              </button>
              {isDropdownOpen && (
                <div 
                  className="absolute right-0 mt-2 py-2 bg-white rounded-md shadow-xl z-50"
                  style={{
                    width: '200px',
                    top: '100%',
                    right: '0',
                  }}
                >
                  {selectedTab !== 'watchlist' && (
                    <button
                      className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      onClick={handleAddToWatchList}
                    >
                      Add to Watch List
                    </button>
                  )}
                  {selectedTab === 'watchlist' && (
                    <button
                      className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </td>
        <td className="py-2 px-4 border-b">
          {`${parseFloat(company.stockPerformance).toFixed(2)} `}
          <span className={getChangeClass(company.stockChange)}>
            {formatChange(company.stockChange)}
          </span>
        </td>
        <td className="py-2 px-4 border-b">
          <TrafficCell 
            trafficData={company.trafficData} 
            trafficChange={company.trafficChange}
          />
        </td>
        <td className="py-2 px-4 border-b">
          {company.positions}
          {company.positionsPercentage !== null && (
            <span className={getChangeClass(company.positionsPercentage)}>
              {` ${formatChange(company.positionsPercentage)}`}
            </span>
          )}
        </td>
        <td className="py-2 px-4 border-b">
          <div className="flex items-center justify-between">
            <span>{formatEmployeeOutlook(company.outlook)}</span>
            <div className="flex items-center">
              <div className="earnings-estimate-tooltip-container">
                <FontAwesomeIcon 
                  icon={faMagicWandSparkles} 
                  style={{...iconStyle, marginRight: '4px'}}
                />
                <div className="earnings-estimate-tooltip">
                  Gen AI assisted summary
                </div>
              </div>
              {expandedRow === company.tickerSymbol 
                ? <FiChevronUp style={iconStyle} /> 
                : <FiChevronDown style={iconStyle} />
              }
            </div>
          </div>
        </td>
      </tr>
      {expandedRow === company.tickerSymbol && (
        <tr>
          <td colSpan="7" className="p-4 bg-gray-100"> {/* Keep colspan as 7 */}
            <AiAnalysisPanel aiAnalysis={company.ai_analysis} stock={company} />
          </td>
        </tr>
      )}
    </>
  );
};

export default CompanyRow;
import React, { useState, useMemo, useCallback } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import CompanyRow from "./CompanyRow";
import EconomicEventRow from "./EconomicEventRow";
import EconomicEventControls from "./EconomicEventControls";
import Tooltip from './Tooltip';

import { estimateNextEarningsDate } from "./utils";
import { SORT_COLUMNS, INITIAL_SORT_CONFIG } from "../config/constants";

const StockTable = React.memo(({
  companyData = [],
  economicEvents = [],
  onAddToWatchList,
  onDelete,
  loadingTable,
  selectedTimeframes,
  setSelectedTimeframes,
  geography,
  setGeography,
  showEconomicEvents,
  setShowEconomicEvents,
  selectedTab, 
  colorSettings
}) => {
  const [sortConfig, setSortConfig] = useState(INITIAL_SORT_CONFIG);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = useCallback((row) => {
    setExpandedRow(prevRow => prevRow === row ? null : row);
  }, []);

  const requestSort = useCallback((key) => {
    setSortConfig(prevConfig => {
      const direction = prevConfig.key === key && prevConfig.direction === "ascending" ? "descending" : "ascending";
      return { key, direction };
    });
  }, []);

  const combinedData = useMemo(() => {
    const today = new Date().toISOString().split("T")[0];
  
    const companyRows = companyData.map((company) => ({
      ...company,
      date: company.earningsDate || estimateNextEarningsDate(company.lastEarningsDate) || today,
      type: "company",
    }));
  
    const economicRows = economicEvents.map((event) => ({
      ...event,
      type: "economic",
    }));
  
    return [...companyRows, ...economicRows].sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [companyData, economicEvents]);

  const sortedData = useMemo(() => {
    return [...combinedData].sort((a, b) => {
      if (sortConfig.key === "date") {
        return sortConfig.direction === "ascending"
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      }
  
      if (sortConfig.key === "name") {
        const aName = a.name || a.eventName || "";
        const bName = b.name || b.eventName || "";
        return sortConfig.direction === "ascending"
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      }
  
      if (sortConfig.key === "trafficChange") {
        const aChange = a.trafficChange ?? 0;
        const bChange = b.trafficChange ?? 0;
        return sortConfig.direction === "ascending"
          ? aChange - bChange
          : bChange - aChange;
      }
  
      const aValue = parseFloat(a[sortConfig.key]) || 0;
      const bValue = parseFloat(b[sortConfig.key]) || 0;
      return sortConfig.direction === "ascending"
        ? aValue - bValue
        : bValue - aValue;
    });
  }, [combinedData, sortConfig.key, sortConfig.direction]);

  const dateColumnStyle = useMemo(() => ({
    width: "110px",
    minWidth: "110px",
    maxWidth: "110px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }), []);

  const renderCompanyRow = useCallback((item, index) => (
    <CompanyRow
      key={item.tickerSymbol || `company-${index}`}
      company={item}
      colorSettings={colorSettings}
      expandedRow={expandedRow}
      toggleRow={toggleRow}
      onAddToWatchList={onAddToWatchList}
      onDelete={onDelete}
      dateColumnStyle={dateColumnStyle}
      selectedTab={selectedTab}
    />
  ), [expandedRow, toggleRow, onAddToWatchList, onDelete, dateColumnStyle, selectedTab, colorSettings]);

  const renderEconomicEventRow = useCallback((item, index) => (
    <EconomicEventRow
      key={`economic-${index}`}
      event={item}
      expandedRow={expandedRow}
      toggleRow={toggleRow}
      index={index}
      dateColumnStyle={dateColumnStyle}
    />
  ), [expandedRow, toggleRow, dateColumnStyle]);

  const renderTableBody = () => {
    if (loadingTable) {
      return (
        <tr>
          <td colSpan={SORT_COLUMNS.length} className="text-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 mx-auto"></div>
            <p>Loading...</p>
          </td>
        </tr>
      );
    }

    return sortedData.map((item, index) => {
      switch (item.type) {
        case "company":
          return renderCompanyRow(item, index);
        case "economic":
          return renderEconomicEventRow(item, index);
        default:
          return null;
      }
    });
  };

  return (
    <div className="overflow-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-100 relative z-10">
            {SORT_COLUMNS.map(({ key, label, tooltip }) => (
              <th
                key={key}
                className="py-2 px-4 border-b text-left font-semibold cursor-pointer select-none relative"
                onClick={() => requestSort(key)}
              >
                <Tooltip text={tooltip}>
                  <div className="flex items-center">
                    {label}
                    {sortConfig.key === key && (
                      <span className="ml-1">
                        {sortConfig.direction === "ascending" ? (
                          <FiChevronUp className="text-gray-500" />
                        ) : (
                          <FiChevronDown className="text-gray-500" />
                        )}
                      </span>
                    )}
                  </div>
                </Tooltip>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={SORT_COLUMNS.length} className="p-0">
              <EconomicEventControls
                selectedTimeframes={selectedTimeframes}
                setSelectedTimeframes={setSelectedTimeframes}
                geography={geography}
                setGeography={setGeography}
                showEconomicEvents={showEconomicEvents}
                setShowEconomicEvents={setShowEconomicEvents}
              />
            </td>
          </tr>
          {renderTableBody()}
        </tbody>
      </table>
    </div>
  );
});

export default React.memo(StockTable);
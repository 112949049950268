export const SORT_COLUMNS = [
  { key: 'date', label: 'Date', tooltip: 'Next earnings date or economic event date' },
  { key: 'name', label: 'Company', tooltip: 'Company name and ticker symbol' },
  { key: 'stockChange', label: 'Stock Performance', tooltip: 'Current stock price and % change since earnings' },
  { key: 'trafficChange', label: 'Traffic', tooltip: 'Website traffic and percentage change over the past 3 months' },
  { key: 'positionsPercentage', label: 'Job Openings', tooltip: 'Open positions and % relative to total employees' },
  { key: 'outlook', label: 'Employee Outlook', tooltip: 'Employee sentiment about the company\'s future' },
];

export const INITIAL_SORT_CONFIG = { key: 'date', direction: 'ascending' };

export const DEFAULT_COLOR_SETTINGS = [
  {
    conditions: [
      { field: 'trafficChange', operator: '>', value: '0' },
      { field: 'stockChange', operator: '<=', value: '0' },
      { field: 'outlook', operator: '>', value: '0.7' }
    ],
    color: '#e6ffe6' // Light green
  },
  {
    conditions: [
      { field: 'trafficChange', operator: '<', value: '0' },
      { field: 'stockChange', operator: '>=', value: '0' }
    ],
    color: '#ffe6e6' // Light red
  },
  {
    conditions: [], // Empty conditions array will match all rows
    color: 'transparent' // Default background for rows that don't match any other rules
  }
];

// Extract field names from SORT_COLUMNS
export const FIELD_NAMES = SORT_COLUMNS.map(column => column.key);
import React from 'react';
import { formatDate, renderEarningsDays } from './utils';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import './EconomicEventRow.css';

const EconomicEventRow = ({ event, expandedRow, toggleRow, index, dateColumnStyle }) => {
  const renderValue = (label, value) => {
    if (value === 'N/A' || value === null || value === undefined) return null;
    return (
      <span className={`ml-2 economic-event-${label.toLowerCase()}`}>
        <span className="font-medium">{label}:</span>
        <span className={`px-1 rounded ${getValueColor(label, value)}`}>
          {value}
        </span>
      </span>
    );
  };

  const getValueColor = (label, value) => {
    if (label === 'Actual' && event.forecast && value !== 'N/A' && event.forecast !== 'N/A') {
      const actualValue = parseFloat(value);
      const forecastValue = parseFloat(event.forecast);
      if (!isNaN(actualValue) && !isNaN(forecastValue)) {
        const isPositiveIndicator = isPositiveEconomicIndicator(event.name);
        if (isPositiveIndicator) {
          return actualValue > forecastValue ? 'economic-value-positive' : 'economic-value-negative';
        } else {
          return actualValue < forecastValue ? 'economic-value-positive' : 'economic-value-negative';
        }
      }
    }
    return '';  // No additional color class for non-Actual values
  };

  const isPositiveEconomicIndicator = (eventName) => {
    const positiveIndicators = [
      'GDP', 'Employment', 'Consumer Confidence', 'Retail Sales',
      'Industrial Production', 'Manufacturing PMI', 'Services PMI'
    ];
    const negativeIndicators = [
      'Unemployment Rate', 'Initial Jobless Claims', 'Inflation Rate', 'Consumer Price Index'
    ];
    
    if (positiveIndicators.some(indicator => eventName.includes(indicator))) {
      return true;
    }
    if (negativeIndicators.some(indicator => eventName.includes(indicator))) {
      return false;
    }
    // Default to positive if not explicitly categorized
    return true;
  };

  const renderDetails = (details) => {
    if (!details) return null;
    return (
      <div className="economic-event-details">
        {details.measures && (
          <p><strong>Measures:</strong> {details.measures.replace(/<\/?p>/g, '')}</p>
        )}
        {details.usual_effect && (
          <p><strong>Usual Effect:</strong> {details.usual_effect.replace(/<\/?p>/g, '')}</p>
        )}
        {details.why_care && (
          <p><strong>Why Care:</strong> {details.why_care.replace(/<\/?p>/g, '')}</p>
        )}
        {details.frequency && (
          <p><strong>Frequency:</strong> {details.frequency}</p>
        )}
      </div>
    );
  };

  const isExpanded = expandedRow === `economic-${index}`;

  return (
    <tr 
      className={`economic-event-row ${isExpanded ? 'expanded' : ''}`}
      onClick={() => toggleRow(`economic-${index}`)}
    >
      <td className="py-2 px-4" style={dateColumnStyle}>
        <div className="text-sm">
          {formatDate(event.date)}
          <br />
          {renderEarningsDays(event.date)}
        </div>
      </td>
      <td className="py-2 px-4" colSpan={6}>
        <div className="flex justify-between items-center">
          <div className="economic-event-name">{event.name}</div>
          <div className="economic-event-data flex items-center">
            {renderValue('Actual', event.actual)}
            {renderValue('Forecast', event.forecast)}
            {renderValue('Previous', event.previous)}
            <span className="ml-2 economic-event-chevron">
              {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
            </span>
          </div>
        </div>
        {isExpanded && renderDetails(event.details)}
      </td>
    </tr>
  );
};

export default EconomicEventRow;

